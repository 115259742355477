<template>
  <div class="register-bg">
    <a-row
      type="flex"
      justify="space-around"
      align="middle"
      class="register"
    >

      <a-col :span="14" class="register-left">
        <img src="@/assets/images/login.jpg">
      </a-col>

      <a-col :span="6" class="register-center">
        <div class="register-form" style="background-color: #fff; padding: 20px; box-sizing: border-box; border-radius: 10px">
          <h1 class="register-title" style="text-align: center;">
            物联网连接管理平台
          </h1>
          <div class="login-border">
            <div class="login-content">
              <div class="welcome-register">
                欢迎注册
              </div>
              <br>
              <first-step
                v-if="!isShowNextStep"
                @completed="nextStep"
              />
              <next-step
                v-else
                @completed="register"
              />
              已有账号?
              <router-link :to="{name: 'login'}"> 点击登录 </router-link>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { createRegisteredAgent } from '@/api/registered_agent'
export default {
  name: 'Register',
  data() {
    // 节流
    this.calmDown = this.$lodash.throttle(this.calmDown, 4000)
    return {
      redirect: undefined,
      submitting: false,
      busy: false, // 为 true 时提示操作频繁
      isShowNextStep: false,
      params: {}
    }
  },
  components: {
    FirstStep: () => import('@/views/register/form/FirstStep'),
    NextStep: () => import('@/views/register/form/NextStep')
  },

  methods: {
    ...mapActions(['LoginAfterSuccessfulRegistration']),

    calmDown() {
      this.busy = false
    },

    tooBusyMessage() {
      this.$message.error('操作太频繁，请稍后再试')
    },

    // 检测是否频繁操作
    checkBusy() {
      this.calmDown()

      if (this.busy) {
        this.tooBusyMessage()
        return true
      } else {
        this.busy = true
        return false
      }
    },

    nextStep(params) {
      this.params = Object.assign(this.params, params)
      this.isShowNextStep = true
    },

    register(params) {
      this.params = Object.assign(this.params, params)
      if (this.loadingCaptchaImg) {
        this.tooBusyMessage()
        return
      }

      if (this.checkBusy()) {
        return
      }

      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.submitting = true
      createRegisteredAgent(this.params).then((res) => {
        if (res.code === 0) {
          this.$success({
            title: '账号创建成功',
            okText: '确定',
            onOk: () => {
              this.LoginAfterSuccessfulRegistration(res.data.token).then(() => {
                this.$router.push({ path: '/registered_agents/home' })
              }).catch(() => {
              })
            }
          })
        }
        this.submitting = false
      })
    }
  }
}
</script>
<style lang="less" scoped>
.register-bg {
  background-color: #000;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 100px 100px 100px;
  overflow: auto;

  .register {
    width: 100%;

    .register-left img {
      width: 100%;
    }

    .register-right {
      display: flex;
      padding: 40px;

      .register-form {
        flex-basis: 400px;
        width: 90%;

        .register-title {
          color: @primary-color;
          text-align: center;
        }

        .login-border {
          margin:0 auto;
          border-radius:8px;

          .login-content {
            padding: 30px 20px 30px 20px;

            .welcome-register {
              font-size: 20px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
